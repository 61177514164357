// Here you can add other styles
body {
    @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap");
}

body {
    font-family: "Kanit" !important;
    background-color: #f3f3f3;
}

.bottom-shadow {
    box-shadow: 0 4px 6px -6px #222;
}

.btn {
    margin: 0 0.3em;
}

.btn-primary {
    background-color: #0076fe;
    border-color: #0076fe;
    color: #ffffff;
}

.btn-success {
    background-color: #66bf0a;
    border-color: #66bf0a;
    color: #ffffff;
}

.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #ffffff;
}

.btn-danger {
    background-color: #fe1602;
    border-color: #fe1602;
    color: #ffffff;
}

.color-primary {
    color: #0076fe;
}

.color-success {
    color: #66bf0a;
}

.color-warning {
    color: #ffc107;
}

.color-danger {
    color: #fe1602;
}

.color-chocolate {
    color: #d2691e;
}

.c-header .c-subheader {
    border: none !important;

    .c-header-nav {
        min-height: 60px;
    }
}

.icon-button {
    background: none;
    border: none;
    padding: 0px 4px;
}

.icon-button:hover {
    color: #04b640;
}

.ant-table-row:hover {
    td {
        background: #a3a3a3 !important;
    }

}

.nav-tabs .nav-link {
    cursor: pointer;
    border-color: #eef0f2;
    border-bottom-color: rgb(196, 201, 208);
    border-bottom: unset;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #232324;
}

.pre-line {
    white-space: pre-line;
}

.table th,
.table td {
    padding: 0.5rem;
}

.table-sm td,
.table-sm th {
    padding: 0.3rem;
}

.text-button {
    color: #fe1602;
    cursor: pointer;
    padding: 0.375rem;
    margin: 0.3em;
}

.text-header {
    display: inline;
    margin: 0px;
}

.metrics+.metrics {
    border-left: 1px solid #e5e5e5;
}

.toggle-switch {
    line-height: 18px;
    -moz-box-align: center;
    align-items: center;
    margin-bottom: 8px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.15) none repeat scroll 0% 0%;
    border-radius: 3em;
    padding: 1px;
}

.toggle-switch input {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
    padding: 0px;
    border: medium none;
    background: transparent none repeat scroll 0% 0%;
    cursor: pointer;
    border-radius: 3em;
}

.toggle-switch span {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    display: inline-block;
    padding: 7px 15px;
    transition: all 100ms ease-out 0s;
    user-select: none;
    border-radius: 3em;
    color: rgba(51, 51, 51, 0.6);
    background: transparent none repeat scroll 0% 0%;
}

.toggle-switch input:checked~span:last-of-type,
.toggle-switch input:not(:checked)~span:first-of-type {
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px;
    color: rgb(51, 51, 51);
    padding: 7px 15px;
}

.toggle-switch span:first-of-type {
    padding-right: 8px;
}

.toggle-switch span:last-of-type {
    padding-left: 8px;
}

.toggle-switch.toggle-switch-primary input:checked~span:last-of-type {
    background-color: #2196f3;
    color: #fff;
}

.toggle-switch.toggle-switch-success input:checked~span:last-of-type {
    background-color: #66bf0a;
    color: #fff;
}

.toggle-switch.toggle-switch-danger input:checked~span:last-of-type {
    background-color: #fe1602;
    color: #fff;
}

.nav-tabs-sm .nav-link {
    padding: 0.2rem 0.5rem;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.react-resizable {
    position: relative;
    background-clip: padding-box;
}

.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}